<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="400px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Importar
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="file"
              >
                <v-file-input
                  v-model="file"
                  prepend-icon=""
                  accept=".csv"
                  hide-details="auto"
                  label="Arquivo"
                  hint="CSV com as seguintes colunas em ordem: tipo;nome;cidade;formação/cargo/setor;instituição/empresa;email;telefone"
                  persistent-hint
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loading"
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-import'"
          />
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import enrolledApi from '@/api/enrolled'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      eventId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        file: null,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {

      async save () {
        try {
          this.loading = true
          const fileBase64 = this.file ? await this.toBase64(this.file) : null

          await enrolledApi.import(this.eventId, { file: fileBase64 })

          this.$emit('imported')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading =false
        }
      },

      async toBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        })
      },

    },

  }
</script>
